import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  BaseStatus,
  MasterDataViewModel,
  ProductListViewModel,
  SuppliesViewModel
} from '@/api/api';
import {
  suppliesListDispatcher,
  SuppliesListInput,
  suppliesListNamespace
} from './store/supplies-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { ProductStatus } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions, StringFormat } from '@/utility/utils';
import router from '@/router';
import ProcessingSettingModal from '@/components/processingSetting/ProcessingSettingModal.vue';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { mapActions, mapState } from 'vuex';
import SuppliesFormModal from '../suppliesForm/SuppliesFormModal.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    ProcessingSettingModal,
    SuppliesFormModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: suppliesListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ],
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class SuppliesList
  extends Vue
  implements ListVue<SuppliesViewModel, SuppliesListInput> {
  @suppliesListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    SuppliesViewModel,
    SuppliesListInput
  >[ListGetter.state];
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;

  BASE_STATUS_ENUM = BaseStatus;
  suppliesStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);

  $refs!: {
    suppliesFormModal: any;
  };

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }
    suppliesListDispatcher.load();
  }

  displayProductStatus(value) {
    return this.suppliesStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.suppliesStatusOptions.find((x) => x.value === value)
            ?.text as string
        ).toString()
      : '';
  }

  edit(id: number) {
    this.$refs.suppliesFormModal.openEditModal(id);
  }

  remove(id: number) {
    const client = new ApiClientFactory().suppliesClient();
    client.delete(id).then(() => {
      suppliesListDispatcher.load();
      // show toast
      this.$bvToast.toast(
        StringFormat(
          this.$t('DeleteSuccessfully').toString(),
          this.$t('Supplies').toString().toLowerCase()
        ),
        {
          title: this.$t('Supplies').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  // settingProcessing(id, name) {
  //   this.$refs.processingSettingModal.loadProcessingSetingModal(id, name);
  // }

  // isNotAbleToSettingProcessing(categoryId: number): boolean {
  //   const catalogueCategoryId = this.masterData.catalogueCategoryId;
  //   const stickerCategoryId = this.masterData.stickerCategoryId;

  //   return (
  //     categoryId === catalogueCategoryId || categoryId === stickerCategoryId
  //   );
  // }

  headers = [
    {
      label: 'STT',
      field: 'orderNumber',
      sortable: false,
      width: '3%'
    },
    {
      label: this.$t('Name'),
      field: 'name'
    },
    {
      label: this.$t('Category'),
      field: 'suppliesCategoryName',
      width: '20%'
    },
    {
      label: this.$t('Status'),
      field: 'status',
      width: '15%'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
