var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_vm._t("default")],2),_c('b-modal',{ref:"processingSettingModal",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Save'),"ok-only":"","centered":"","title":'Cài đặt gia công: ' + _vm.productName,"no-close-on-backdrop":"","size":"xl"},on:{"ok":function($event){$event.preventDefault();return _vm.submit($event)},"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Lưu ")]),_c('b-button',{staticClass:"mx-1 float-right",attrs:{"variant":"outline-primary"},on:{"click":_vm.addProcessingModel}},[_vm._v(" Thêm mục ")])],1)]},proxy:true}])},[(_vm.processingModels)?_c('div',{staticClass:"processingSetting"},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_vm._l((_vm.processingModels),function(item,index){return [_c('b-col',{key:index,attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":'processingModel' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-row',[_c('b-col',{staticClass:"pr-0"},[_c('b-form-input',{staticClass:"outline",attrs:{"state":errors.length > 0 ? false : null,"placeholder":("Ghi chú " + (index + 1))},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()],1),_c('b-col',{staticClass:"px-0 align-self-center",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"py-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProcessingItem(
                              $event,
                              _vm.processingIndex,
                              index
                            )}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"22"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"ml-1 processing-item-list"},[_vm._l((item.items),function(processingItem,processingIndex){return [_c('div',{key:'item-' + processingIndex},[_vm._v(" "+_vm._s(processingItem.name)+" "),_c('feather-icon',{staticClass:"clickable-icon m-1",attrs:{"icon":"Edit2Icon","size":"16"},on:{"click":function($event){return _vm.editProsessingItem(index, processingIndex)}}}),_c('feather-icon',{staticClass:"clickable-icon",attrs:{"icon":"XIcon","size":"17"},on:{"click":function($event){return _vm.removeProcessingItem(processingIndex, index)}}})],1)]}),_c('div',{staticClass:"w-60",attrs:{"align-h":"between"}},[_c('b-form-group',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":'processingItemModel' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var failedRules = ref.failedRules;
return [_c('b-row',[_c('b-col',{staticClass:"pr-0"},[_c('b-form-input',{ref:'refItem' + index,refInFor:true,staticClass:"mr-auto inline",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"gia công..."},on:{"blur":function($event){return _vm.updateProcessingItem($event, index)}},model:{value:(item.edittingValue),callback:function ($$v) {_vm.$set(item, "edittingValue", $$v)},expression:"item.edittingValue"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()],1),_c('b-col',{staticClass:"px-0 align-self-center",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"py-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.updateProcessingItem(
                                  $event,
                                  _vm.processingIndex,
                                  index
                                )}}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"22"}})],1)],1)],1)]}}],null,true)})],1)],1)],2)],1)]})],2)],1)],1)],1):_c('div',[_vm._v("No items")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }