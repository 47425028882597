import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  FileParameter,
  ProductCategoryOrderNumber,
  SuppliesViewModel,
  BaseStatus,
  SuppliesCategoryViewModel
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import draggable from 'vuedraggable';
import {
  SuppliesAction,
  SuppliesGetter,
  SuppliesNamespace
} from '@/store/supplies/supplies.module-types';
import {
  SUPPLIES_CATEGORY_STATE_NAMESPACE,
  SuppliesCategoryAction,
  SuppliesCategoryGetter,
  SuppliesCategoryNamespace
} from '@/store/suppliesCategory/suppliesCategory.module-types';
import { dispatchSuppliesAction } from '@/store/supplies/supplies.dispatch';
import { SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE } from '@/views/suppliesCategory/suppliesCategoryList/store/SuppliesCategory-list.module';
import { mapActions } from 'vuex';
import { suppliesListDispatcher } from '../suppliesList/store/supplies-list.module';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    BFormCheckbox,
    draggable,
    BListGroup,
    BListGroupItem
  },
  methods: {
    ...mapActions(SUPPLIES_CATEGORY_STATE_NAMESPACE, [
      SuppliesCategoryAction.fetchSuppliesCategories
    ])
  }
})
export default class SuppliesFormModal extends Vue {
  @SuppliesNamespace.Getter(SuppliesGetter.supplies)
  supplies!: SuppliesViewModel;
  @SuppliesCategoryNamespace.Getter(SuppliesCategoryGetter.suppliesCategories)
  suppliesCategories!: SuppliesCategoryViewModel[] | null;
  fetchSuppliesCategories!: () => Promise<void>;

  input = this.getDefaultInputValue();
  orderingListLoading = false;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  suppliesStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    oderingModal: InstanceType<typeof BModal>;
  };

  orderingList: ProductCategoryOrderNumber[] = [];

  @Watch('supplies')
  suppliesCategoryUpdated(): void {
    if (this.supplies) {
      this.input = {
        image: null,
        name: this.supplies.name as string,
        status: this.supplies.status as BaseStatus,
        metaDescription: this.supplies.description as string,
        suppliesCategoryId: this.supplies.suppliesCategoryId,
        orderingNumber: this.supplies.orderNumber
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  openCreateModal(): void {
    dispatchSuppliesAction(SuppliesAction.clearSupplies);
    this.input = this.getDefaultInputValue();
    debugger;
    if (!this.suppliesCategories || this.suppliesCategories.length === 0) {
      this.fetchSuppliesCategories();
    }
    this.$refs.modal.show();
  }

  async openEditModal(id: number): Promise<void> {
    dispatchSuppliesAction(SuppliesAction.loadSupplies, id);
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      image: null,
      status: BaseStatus.Active,
      metaDescription: '',
      suppliesCategoryId: 0,
      orderingNumber: 0
    };
  }

  add(): void {
    const client = new ApiClientFactory().suppliesClient();
    // const image: FileParameter = {
    //   data: this.input.image,
    //   fileName: (this.input.image as any).name
    // };

    client
      .create(
        this.input.name,
        this.input.metaDescription,
        0,
        this.input.suppliesCategoryId,
        this.input.orderingNumber
      )
      .then(() => {
        suppliesListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm mới vật tư thành công', {
          title: 'Vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().suppliesClient();
    const image: FileParameter | null = this.input.image
      ? {
          data: this.input.image,
          fileName: (this.input.image as any).name
        }
      : null;

    client
      .edit(
        this.supplies.id,
        this.input.name,
        this.input.metaDescription,
        this.input.orderingNumber,
        this.input.suppliesCategoryId,
        this.input.status
      )
      .then(() => {
        suppliesListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa vật tư thành công', {
          title: 'Vật tư',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
    }
  }
  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.supplies) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }

  // openOrderingModel() {
  //   this.$refs.oderingModal.show();
  //   this.orderingListLoading = true;

  //   const client = new ApiClientFactory().su();
  //   client
  //     .getListByOrderNumber()
  //     .then((result) => {
  //       if (result) {
  //         this.orderingList = result.sort(
  //           (a, b) => a.orderNumber - b.orderNumber
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       this.$bvToast.toast('Tải danh sách thất bại', {
  //         title: 'Danh mục sản phẩm',
  //         toaster: 'b-toaster-bottom-right',
  //         variant: 'danger'
  //       });
  //     })
  //     .finally(() => {
  //       this.orderingListLoading = false;
  //     });
  // }

  // onSortOrderingSubmit(data) {
  //   this.orderingList.forEach((el, index) => {
  //     el.orderNumber = index;
  //   });

  //   const client = new ApiClientFactory().productCategoryClient();
  //   client
  //     .updateOrderNumber({
  //       productCategoryOrderNumbers: this.orderingList
  //     } as UpdateCategoryOrderNumbers)
  //     .then((result) => {
  //       if (result) {
  //         this.$bvToast.toast('Lưu thành công', {
  //           title: 'Thứ tự danh mục sản phẩm',
  //           toaster: 'b-toaster-bottom-right',
  //           variant: 'success'
  //         });
  //       } else {
  //         this.$bvToast.toast('Lưu thất bại', {
  //           title: 'Thứ tự danh mục sản phẩm',
  //           toaster: 'b-toaster-bottom-right',
  //           variant: 'danger'
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.$bvToast.toast('Lưu thất bại', {
  //         title: 'Thứ tự danh mục sản phẩm',
  //         toaster: 'b-toaster-bottom-right',
  //         variant: 'danger'
  //       });
  //     })
  //     .finally(() => {
  //       this.orderingListLoading = false;
  //     });
  // }
}
