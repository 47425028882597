import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  SuppliesActions,
  SUPPLIES_STATE_NAMESPACE
} from './supplies.module-types';

export const dispatchSuppliesAction: DispatchAction<SuppliesActions> = getDispatch(
  SUPPLIES_STATE_NAMESPACE
);
