import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption,
  BFormCheckbox,
  BRow,
  BCol
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import ApiClientFactory from '@/api/apiClientFactory';
import { ProcessingTypeInput, ProcessingTypeModel } from '@/api/api';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    BFormCheckbox,
    BRow,
    BCol
  }
})
export default class ProcessingSettingModal extends Vue {
  productId!: number;
  productName!: string;

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    processingSettingModal: InstanceType<typeof BModal>;
  };

  processingModels: any[] = [];

  isInputItem = false;
  processingModelItemInput = '';

  isInputModel = false;
  processingModelInput = '';

  loadProcessingSetingModal(productId, productName) {
    this.productId = productId;
    this.productName = productName;

    const client = new ApiClientFactory().productClient();
    client
      .getProcessingTypes(productId)
      .then((result) => {
        if (result) {
          this.processingModels = [];
          this.processingModels = result.map((s) => {
            return {
              id: s.id,
              name: s.name,
              items: s.processingTypeItemModels?.map((p) => {
                return { id: p.id, name: p.name };
              }),
              edittingValue: '',
              edittingItem: ''
            };
          });

          console.log('mapping: ', this.processingModels);
        } else {
          // show toast
          this.$bvToast.toast('Tải gia công thất bại', {
            title: 'Cài đặt gia công',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((ex) => {
        // show toast
        this.$bvToast.toast(ex.message, {
          title: 'Cài đặt gia công',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });

    this.$refs.processingSettingModal.show();
  }

  addProcessingModel() {
    if (this.processingModels && this.processingModels.length < 4) {
      this.processingModels.push({
        id: '',
        name: '',
        items: [],
        edittingValue: '',
        edittingItem: ''
      });
    }
  }

  addProcessingItem(name) {
    const index = this.processingModels.findIndex((s) => s.name == name);
    if (index !== -1) {
      this.processingModels[index].items.push({ id: '', name: '' });
    }
  }

  updateProcessingItem(value, index) {
    if (
      (index && this.processingModels[index].name === '') ||
      value.target.value === ''
    ) {
      return;
    }

    if (this.processingModels[index].edittingItem != '') {
      const editIndex = this.processingModels[index].items.findIndex(
        (s) => s.name == this.processingModels[index].edittingItem
      );
      if (editIndex !== -1) {
        const editItem = this.processingModels[index].items[editIndex];
        this.processingModels[index].items.splice(editIndex, 1, {
          ...editItem,
          name: value.target.value
        });
        this.processingModels[index].edittingItem = '';
      }
    } else {
      this.processingModels[index].items.push({
        id: '',
        name: value.target.value
      });
    }
    this.processingModels[index].edittingValue = '';
    //const inputs = this.$refs[`refItem${index}`];
  }

  cancelEditing() {
    this.isInputItem = false;
    this.processingModelItemInput = '';
  }

  removeItem(index) {
    const processingModel = this.processingModels[index];
    if (processingModel) {
      this.processingModels.splice(index, 1);
    }
  }

  removeProcessingItem(itemIndex, index) {
    const processingModel = this.processingModels[index];
    if (processingModel) {
      processingModel.items.splice(itemIndex, 1);
    }
  }

  submit() {
    console.log(this.processingModels);
    const client = new ApiClientFactory().productClient();
    const model = [
      ...this.processingModels.map((s) => {
        return {
          ...s,
          processingTypeItemModels: s.items
        } as ProcessingTypeModel;
      })
    ];
    client
      .createProcessingType({
        processingTypeModels: model,
        productId: this.productId
      } as ProcessingTypeInput)
      .then((success) => {
        if (success) {
          // show toast
          this.$bvToast.toast('Cài đặt gia công thành công', {
            title: 'Cài đặt gia công',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });

          this.$refs.processingSettingModal.hide();
        } else {
          // show toast
          this.$bvToast.toast('Cài đặt gia công thất bại', {
            title: 'Cài đặt gia công',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((ex) => {
        // show toast
        this.$bvToast.toast(ex.message, {
          title: 'Cài đặt gia công',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  resetModal() {
    this.processingModels = [];
  }

  editProsessingItem(index, itemIndex) {
    const processingModel = this.processingModels[index];
    if (processingModel) {
      const processingItem = processingModel.items[itemIndex];
      processingModel.edittingValue = processingItem.name;
      processingModel.edittingItem = processingItem.name;
    }
  }
}
